import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "layout": "post",
  "title": "2020 Reading List",
  "author": "Purepost",
  "date": "2020-01-20T08:09:24.000Z",
  "categories": ["books", "growth mindset", "personal development", "Personal-Professional Growth", "professional development", "reading"],
  "slug": "2020-reading-list",
  "draft": false,
  "meta_title": "2020 Reading List"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`A new year and decade have begun. Many of us are designing our 2020-setting goals and resolutions, picking a word to live by, and creating to-do lists. Maybe one of your to-do lists is a reading list. To recognize the tradition of setting goals for the new year, the team at `}<strong parentName="p">{`Purepost`}</strong>{` would like to share their `}<a parentName="p" {...{
        "href": "http://blog.purepost.co/wp-content/uploads/2020/01/Infographic_Top5Books.pdf"
      }}>{`top personal and professional growth books`}</a>{` to inspire you in 2020.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "816px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "129.375%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAaCAIAAAA44esqAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAE5UlEQVQ4yz1U2YscRRjv/0/wWV8En1VQRBAURDAmcbMoGiNiNMQYEDdivN40q2ay2Z3sMdPd0/fd0z0909PVR1VXX3VI74jfQ1EfX33371dC37O+Z21Lu44SwjCmXce6rk+SbRRt1+vtdltRyoeBNQ0jhHUdGwbGGeecC13HGkzbbjS0La1rwjlHqDk9nT158kySFEXRu45yztt2TDMMjDHO2BhO+D/zztD347s8r/b2Pr59++7XX9+5efNWGEac86YZ/Sgdnw0DI+Qy8zCMxXDOGR3zc84hrKdTUZZV03As00EI8UvZ1c/oThud6TCwth2rPTg4uHbto++//+HOnfvXr9+4d+/+wYMHfz768/Dw0U8/Pdzb2z89vbgM8Z+3cKkwBHtK+D9/HX315Z0be59ev3rj5mdf/PrLb9euXr/ywYd/Hz7+5va3+3ufnJ/JXcNr1FPKds6sKmESZUmcobJt8YBRV1dtAep8C8u8Xq9ACdoGUTrwGrZV3pQ5xpiMPQ8Dmc/Fo6Onxycn0+mJJIkXFxdzca4oiqJqhmkpqmHbXl4UeQGaFu8KZox3HRMY40EQa6qhqbo4FzVVV1Xdsb0kSdfrJAzDIFhFcZRtt+tkk24AxqSue0LGtgUI4eRo8sfhoed5q1Xi+2GSbMIwDoKlZTmW5XmeH0WR5y2DIA7DeLMBSZIBACnlwvnF2Zuvv3Rr/z1JXhAywgPCBqG2wW3TdFXV9H2PcVdVLa5bCJuqasqyrqq676lwOn364vPPvfbCS48O/3HcYCGbqmqZpmlZtqHvGnblha6q5mJhyrLuumEcrzwvxLgTQFY8/PHnBz88XCebqkQgK0FWFzmqUVMjDLIawQbBJtvCbYpAhmo0dCMRLvfc94Nt27KkqIopzjVlocvSQtccTdE91/e99TYFcbSN4yJaFkXewKpHsB/xzblQ1/j4+NmTydHx8XQ6PT87m4miZBimomiSJIuiIsmaKGmWFRiGH4ZryjihbEcrocGtohrn53PTdFw3DIJlGMYA5GkKiqIsSwQAWq1KCPu+5zs+7bBNCBM0Xdy/9taVN16eHE9cN9B1e1yOH2ia6TiObXu27Wma6zqBH8RxvAqCeLlcRdG6aQZBV2cfv/PKrbdf/ePwL123VUU1DE1V9dlMNAxDVbXFQpdlRZJkSVJk2ZQkXRSVNM0opYJhON/dvff7z7/atg8A3KbFZgPStAAAQIiKoqyqCoAcgDJNMwRrMspA6chCAWRYVa3pycxz/O0mC7zYdZLAX0fLVRjEgR+vV2m63uZZ0eKhwR2uuxq2De7JQAXGmGnqT59OHz8+mUxOT05Onz2bTqenorgQxYWq6qZpGYbhOCN4PW9pWYFt+2EYIYgFQohtu7OZPJ/LZ2ez2UyRJXVklGpZlmuapjaOy/f9UNcdRXGCIFouIwByQqgAIZ5MppMnx4qiz+eKrttxvFkuoyTZJElaFEWe51UFIazTDaxKBGENACpLVKNeyMv6/tV3Dj7f32TVdpxTAUBZlmWeF1lWXVJ3/PRwTepxWBRj0rak7wnGRHDc8Kv33/3ywyuSYhq6qSqGKGqXlIx8P/JHCcIwdN3AdQPH8R0nsG3PdcOigIKuW9/c/fHb+w98PyjLLssQQphS2jSkrseVUEoZo8NAh348m4Y0DenHO/sXhGpnMGaoDS4AAAAASUVORK5CYII=')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Slide1 1",
        "title": "Slide1 1",
        "src": "/static/53b89fa3df05641a4d7669dda4254ca1/b4098/Slide1-1.png",
        "srcSet": ["/static/53b89fa3df05641a4d7669dda4254ca1/72799/Slide1-1.png 320w", "/static/53b89fa3df05641a4d7669dda4254ca1/6af66/Slide1-1.png 640w", "/static/53b89fa3df05641a4d7669dda4254ca1/b4098/Slide1-1.png 816w"],
        "sizes": "(max-width: 816px) 100vw, 816px",
        "style": {
          "width": "100%",
          "height": "100%",
          "margin": "0",
          "verticalAlign": "middle",
          "position": "absolute",
          "top": "0",
          "left": "0"
        },
        "loading": "lazy",
        "decoding": "async"
      }}></img>{`
    `}</span>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "816px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "129.375%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAaCAIAAAA44esqAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAE/UlEQVQ4yyVUSavkVBSu3+TGjQjuHNeKDUJ3Yy8EocFe2CAoNi7cOKAbEZcKTaMijT1oD49+7/WrelWVVCrzHZKbm6SSmlJDxpubm9K8vpzFgcvhnPN93/l6ZckJ8XXdJiQghFIaExIuFsvttlitsv0+32zyPC/Lst7vWVXVWcaqqjlcvF5R1Kcng5PjviwrZ2fD88FE1/ThUJJlTZb18VjRdRsAbNu2roPpVLdtbJrWep00TdvLsvL5SH3el6Sx/Pjx07E0kSRpMBiNxxqExPcDzwsoDcMwjKIFY1WWlVXFGWua5tC7h/ff3jv+ru+50QpDqOu2ZVmKYk1kayKriqJrmgkhdBwXAIcQH2MSxzHndTf299L+488+vfbjb0a4JhiaZheqCiwLWRaEAFFKHQcj5ELoABsBgDDGWZZ2xbcf9V97/e0PLl91SDee41AIqev6m80+TbOyLPI8T9Nsu83SNE+SNEn2aZq3bdsV5/vtVFEcSMq8ZpXgrOFM1FVT5LwqRVVwVooiF3nKi4xXRVOV3VdRNB1gRVlpqu5gAiEihAAA3G49aBgmIX4czcMw3u8KIdoX9HQtL1LG2l5ZloPhRDeBZdmqqimKOp2qiqJKkqwoEwCgqpqqqoZhEAQzQoL5fB1Fy9VqXdesh7Hz9O8/jh7cXa2WYTi74MafhQtKg+VyRemM0mA+jz0v8LyQED+KFpRGcZwI0fYe/nXnk7deuvLGy+PhOcLEMCxVNSB0AeiANQxg25am6Zpm2zbSdcs0oa6DIIg5b3vj00cfXbn8w60bHlBNC6sqMAwAIUQYI+Qg5IbhzHUD1w0cJ3Acn5DA8+h2mwlx6Gmadef3v/598DCgNFnvlotNNFtH0Wq5WK+W68V8l+fVfp9vt2WaNnnWFHnDSlGVHYC9oigcxwG2Y5nINJBlIttChg5dh2DkeGS+SXazcDkLt8ma7XbVfseyPRNCdDxvNpujp8f9/vDZs9N+fzQcyWdnfUmSB4Nhv9+XpKlhANNEhgFN06F0vl4ny+W6ri/kmeclgARjYl9IDwCEkIMxNk3ouq6mGZoGVBVPpzYhsyiaJ8lmsVhzzrud5dNHP3918+YXX8aL1eFwqCrRtq0QB847JdS14LzNMsGYKEvOGGdMMNbUdduJ5NmT+3duXHv31Vfu//M0DOfU96Oo49MjfhD4QRC6LiUuDYKQ+hHGHeauS+fz1eHQ9s6Hyk+XPvz6vff7/dFikQRB/CJms1kcz/O8qKoyz4s0LbKsKMuqLMuqqhir2rbtBcHqeDAejNV53AG7iJPNmhWZEE3b1IeqaHjdtqJt6pazljPBa1GztuGHTmFpmvbPng+H0snJ+ZMnp2dnw9FodH4+Go8VWZ6ORpIsT0zTsm2oqpplIU0Dvh/V9cVVMcYkSRmN5PFIPjk5m0z083MJANsyEYQOghhCBEDnJLaNLdN1MNnudkK0XWfoBPf/vv/k6Jj6sWlCABzH8ZIkWa2S3S5/cYZCHJqmzfOW8y5hrOW8Lcu298vtP29devPWjeuKhX0aUhpA6HoedV0XQpdS6vu+51GMKEKUEA8hD2O63e6rqu39evvON1ff+fzG9eHEUKeqppmGgTEmCDmW1dkVIR7GuDMw8MLJiG1j1w0Ya3qmYT+4e/fo6Nl2l87jbZJknDec8yKvs4w3TSOEaJqmEwZrOG+KosnzTi3/n+R/ex9UWN0pHaIAAAAASUVORK5CYII=')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Slide2 1",
        "title": "Slide2 1",
        "src": "/static/cd8af9bbd580d6c7e361bc8995879b60/b4098/Slide2-1.png",
        "srcSet": ["/static/cd8af9bbd580d6c7e361bc8995879b60/72799/Slide2-1.png 320w", "/static/cd8af9bbd580d6c7e361bc8995879b60/6af66/Slide2-1.png 640w", "/static/cd8af9bbd580d6c7e361bc8995879b60/b4098/Slide2-1.png 816w"],
        "sizes": "(max-width: 816px) 100vw, 816px",
        "style": {
          "width": "100%",
          "height": "100%",
          "margin": "0",
          "verticalAlign": "middle",
          "position": "absolute",
          "top": "0",
          "left": "0"
        },
        "loading": "lazy",
        "decoding": "async"
      }}></img>{`
    `}</span>
    <p>{`Share what you are planning to read in 2020!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      